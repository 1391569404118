import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
	ClickAwayListener, Divider, List, ListItemIcon, ListItem,
	ListItemButton, Typography, ListItemText, Tooltip, ListItemAvatar, Avatar as MuiAvatar,
	CircularProgress, Grow, IconButton,
	Paper, Badge
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { canViewProps } from '../store/security';
import { logOut } from '../store/context';
import { collapseSideMenu } from '../store/layout';
import map from 'lodash/map';
import { getUserMenu } from '../store/menu';
import { withStyles } from 'tss-react/mui';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ImpersonateIcon from '@mui/icons-material/SupervisorAccount';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ButtonLink from './widgets/ButtonLink';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CloseSubMenuIcon from '@mui/icons-material/KeyboardTab';
import NotificationRow from './widgets/NotificationRow';
import { Scrollbars } from 'react-custom-scrollbars-2';
import MessageIcon from '@mui/icons-material/Message';
import Popper from '@mui/material/Popper';
import Avatar from './widgets/Avatar';
import Iconify from './widgets/Iconify';
import isString from 'lodash/isString';
import forOwn from 'lodash/forOwn';
import some from 'lodash/some';
import trim from 'lodash/trim';
import { tooltipClasses } from '@mui/material/Tooltip';

const disabledTooltip = <span style={{ fontSize: 13.5 }}>Sorry, your subscription does not provide you access to this area. Contact us to enquire.</span>;

const styles = (theme, _params, classes) => ({
	navbar: {
		backgroundColor: theme.palette.primary.main
	},
	listItem: {
		'&.Mui-selected': {
			background: 'transparent',
			color: theme.palette.primary.main,
			[`.${classes.avatar}`]: {
				border: `2px solid ${theme.palette.primary.main}`,
				color: theme.palette.primary.main,
			}
		}
	},
	listItemText: {
		'span': {
			fontSize: '14px'
		}
	},
	listItemAvatar: {
		minWidth: 'auto',
		// '&:hover': {
		// 	backgroundColor: theme.palette.secondary.main
		// },
		// '&:hover svg': {
		// 	color: theme.palette.secondary.contrastText
		// },
		// '&.active': {
		// 	backgroundColor: theme.palette.secondary.main,
		// 	color: theme.palette.secondary.contrastText
		// },
		// '&.active svg': {
		// 	color: theme.palette.secondary.contrastText
		// }
	},
	avatar: {
		borderRadius: '12px',
		background: '#ECEEF5',
		border: `2px solid #ECEEF5`,
		color: '#7B849C',
		// '&:hover': {
		// 	backgroundColor: theme.palette.secondary.main
		// },
		'&:hover svg': {
			color: '#00958F'
		},
		// '&.active': {
		// 	backgroundColor: theme.palette.secondary.main,
		// 	color: theme.palette.secondary.contrastText
		// },
		// '&.active svg': {
		// 	color: theme.palette.secondary.contrastText
		// }
	},
	appbarNav: {
		'& .nav-link': {
			'&:hover': {
				color: theme.palette.secondary.main,
				'& svg': {
					color: theme.palette.secondary.main,
				}

			}
		}
	},
	bottomNav: {
		display: 'flex'
	},
	navDropDown: {
		'& .menu-item': {
			'&:hover': {
				color: `${theme.palette.secondary.main} !important`,
				backgroundColor: '#fff'
			}
		}
	},
	menuTooltip: {
		[`& .${tooltipClasses.tooltip}`]: {
			fontSize: '16px',
			borderRadius: '10px',
			background: '#00958F'
		}
	},
	menuDisabledTooltip: {
		[`& .${tooltipClasses.tooltip}`]: {
			fontSize: '16px',
			borderRadius: '10px',
			background: '#00958F'
		}
	}
});

const getItemByPath = (items, path) => {
	let itemName = null;
	forOwn(items, (subitems, key) => {
		if (some(subitems, subitem => {
			return trim(subitem.path, '/') === trim(path, '/');
		})) {
			itemName = key;
		}
	})
	return itemName;
};

const SideMenu = React.memo(props => {
	const [hoverItem, setHoverItem] = React.useState(null);
	const [profileAnchor, setProfileAnchor] = React.useState(null);
	const [notificationsAnchor, setNotificationsAnchor] = React.useState(null);

	const { notifications, canView } = props;

	// Fetch User Menu
	React.useEffect(props.getUserMenu, []);

	const { names = [], labels, icons, disabled, items } = props.userMenu;
	const path = props.history.location.pathname.match(/\/([^/]*)/)[1];
	const activeItem = getItemByPath(items, path);

	const shownMenu = hoverItem || activeItem || 'home';

	const closePopups = () => {
		setProfileAnchor(null);
		setNotificationsAnchor(null);
	};
	const togglePanel = panel => {
		closePopups();
	};
	const markNotification = n => {
		props.markNotification(n);
		togglePanel();
	};

	// const renderMenu = (name, index) => {
	// 	const menuItems = items[name];
	// 	if (!menuItems || menuItems.length === 0) return null;

	// 	// Show dropdown if menu is not disabled and either there is more than one item or the only item is disabled
	// 	const showDropdown = !disabled[name] && (menuItems.length > 1 || menuItems[0].disabled);
	// 	const menuProps = {
	// 		key: index,
	// 		label: labels[name],
	// 		Icon: disabled[name] ? LockIcon : icons[name],
	// 		disabled: disabled[name]
	// 	};

	// 	return showDropdown ? (
	// 		<Menu {...menuProps} name={name}>
	// 			{map(menuItems, (item, i) => <MenuItem key={i} {...item} />)}
	// 		</Menu>
	// 	) : <MenuItem {...menuProps} path={menuItems[0].path} />;
	// }

	const getIconComponent = name => {
		const icon = icons[name];
		if (isString(icon)) {
			return () => <Iconify icon={icon} />;
		} else {
			return icon;
		}
	};

	const renderTopMenu = (name, index) => {
		const menuItems = items[name];
		if (!menuItems || menuItems.length === 0) return null;

		const menuProps = {
			key: index,
			label: labels[name],
			Icon: disabled[name] ? LockIcon : getIconComponent(name),
			disabled: disabled[name],
			onMouseEnter: () => setHoverItem(name),
			onClick: () => setHoverItem(name),
			hasChildren: items[name].length > 1
		};

		return <MenuItem {...menuProps} path={menuItems[0].path} collapsed={true} />;
	}
	const renderSubMenu = () => {
		if (!items) return <></>;
		const menuItems = items[shownMenu];

		return <React.Fragment>
			{map(menuItems, (item, i) => <SubMenuItem key={i} {...item} />)}
		</React.Fragment>;
	}

	// const Menu = ({ name, label, Icon, children }) =>
	// 	<React.Fragment>
	// 		<ListItemButton
	// 			className={props.classes.listItem}
	// 			selected={name === props.active} button onClick={() => setOpen(name)}>
	// 			{Icon && <ListItemAvatar
	// 				className={props.classes.listItemAvatar}>
	// 				<MuiAvatar className={props.classes.avatar}>
	// 					<Icon />
	// 				</MuiAvatar>
	// 			</ListItemAvatar>}


	// 			<ListItemText primary={label} className={props.classes.listItemText} />
	// 			<ExpandMore />
	// 		</ListItemButton>
	// 	</React.Fragment>

	const MenuItem = ({ label, path, Icon, disabled, collapsed, onMouseEnter, onMouseLeave, hasChildren }) => disabled ?
		<Tooltip title={disabledTooltip} placement="right" classes={{ popper: props.classes.menuDisabledTooltip}}>
			<span>
				<ListItemButton disabled
					className={props.classes.listItem}
				>
					<ListItemAvatar className={props.classes.listItemAvatar}>
						<MuiAvatar className={props.classes.avatar}>
							<LockIcon />
						</MuiAvatar>
					</ListItemAvatar>
					{!collapsed && <ListItemText primary={label} className={props.classes.listItemText} />}
				</ListItemButton>
			</span>
		</Tooltip> :
			
		<Tooltip title={collapsed ? label : ""} placement="right" classes={{ popper: props.classes.menuTooltip}}>
			<ListItemButton
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className={props.classes.listItem}
				selected={Icon && path === props.location.pathname} 
				button 
				component={Link} 
				to={path}
				onClick={() => {
					if (hasChildren) {
						props.collapseSideMenu(false);
					} else {
						props.collapseSideMenu(true);
					}
				}}
			>
				{Icon && <ListItemAvatar className={props.classes.listItemAvatar}>
					<MuiAvatar className={props.classes.avatar}>
						<Icon />
					</MuiAvatar>
				</ListItemAvatar>}
				{!collapsed && <ListItemText inset={!Icon} primary={label} lassName={props.classes.listItemText} />}
			</ListItemButton>
		</Tooltip>;

	const SubMenuItem = ({ label, path, Icon, disabled }) => disabled ?
		<Tooltip title={disabledTooltip} placement="right" classes={{ popper: props.classes.menuDisabledTooltip}}>
			<span>
				<ListItemButton disabled
					className={props.classes.listItem}

				>
					<ListItemText style={{ paddingLeft: 0 }} primary={label} lassName={props.classes.listItemText} />
				</ListItemButton>
			</span>
		</Tooltip> :
		<ListItemButton
			className={props.classes.listItem}
			selected={Icon && path === props.location.pathname} 
			button 
			component={Link} 
			to={path}
			onClick={() => {
				props.collapseSideMenu(true);
			}}
		>
			<ListItemText primary={label} className={props.classes.listItemText} />
		</ListItemButton>;

	return (
		<div className={`side-menu${props.isSideMenuCollapsed ? ' side-menu-collapsed' : ''}`}>
			<div className="menus">
				<List component="nav" className="top-menu">
					{map(names, renderTopMenu)}
				</List>
				<List 
					className="sub-menu"
					onMouseLeave={() => setHoverItem(null)}
					component="nav"
				>
					<IconButton
						onClick={() => props.collapseSideMenu(true)}
						style={{
							position: 'absolute',
							right: 0,
							top: 0
						}}
					>
						<CloseSubMenuIcon  style={{ transform: 'rotate(180deg)' }} />
					</IconButton>
			
					{/* <h4 style={{ textTransform: 'uppercase', marginBottom: '10px' }}>{shownMenu}</h4> */}
					{renderSubMenu()}


				</List>
			</div>
			<div className="side-menu-bottom">
				<ClickAwayListener onClickAway={closePopups}>
					<div className={` ${props.classes.bottomNav}`} onMouseEnter={closePopups}>
						{canView.contact &&
							<div >
								<ListItemButton
									component={Link} to="/contact-us"

								>
									<ListItemAvatar className={props.classes.listItemAvatar}>
										<MuiAvatar className={props.classes.avatar}>
											<ContactSupportIcon />
										</MuiAvatar>
									</ListItemAvatar>
								</ListItemButton>
							</div>
						}
						{/* Notifications */}
						<div id="nav-notifications">
							<ListItemButton
								onClick={(e) => {
									setProfileAnchor(null);
									setNotificationsAnchor(notificationsAnchor ? null : e.currentTarget);
								}}
								aria-label="Notifications"

							>
								<ListItemAvatar className={props.classes.listItemAvatar}>
									<MuiAvatar className={props.classes.avatar} sx={{ overflow: 'visible' }}>
										<Badge badgeContent={notifications.length} max={9} color="error"><MessageIcon /></Badge>
									</MuiAvatar>
								</ListItemAvatar>
							</ListItemButton>

							<Popper open={!!notificationsAnchor} anchorEl={notificationsAnchor} placement="top-end" style={{ zIndex: 99999 }} disablePortal={true}
								transition popperOptions={{ positionFixed: true }}>
								{({ TransitionProps }) =>
									<Grow {...TransitionProps} timeout={350} mountOnEnter unmountOnExit>
										<Paper className="notifications-panel" elevation={1}>
											<div style={{ padding: "15px 20px", borderBottom: "1px solid rgba(120, 130, 140, 0.13)" }}>
												<Typography variant="subtitle1">
													Notifications
													{props.notificationsLoading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
												</Typography>
												{notifications.length === 0 && <Typography variant="caption">You do not have any notifications...</Typography>}
											</div>
											{notifications.length > 0 &&
												<React.Fragment>
													<Scrollbars className="notifications" autoHide autoHeight autoHeightMax={250}>
														{map(notifications.slice(0, props.shownNotificationsCount), (n, i) =>
															<NotificationRow key={i} notification={n} onClick={markNotification} />
														)}
													</Scrollbars>
													<ButtonLink to="/profile#notifications" fullWidth size="large" onClick={togglePanel}>
														Check All Notifications
													</ButtonLink>
												</React.Fragment>
											}
										</Paper>
									</Grow>
								}
							</Popper>
						</div>
						<div>

						</div>

						{/* Profile */}
						<div id="nav-profile">
							<ListItemButton
								onClick={(e) => {
									setNotificationsAnchor(null);
									setProfileAnchor(profileAnchor ? null : e.currentTarget);
								}}
								aria-label="Profile"
								sx={{ height: '100%' }}
							>
								<ListItemAvatar className={props.classes.listItemAvatar}>
									<Avatar src={props.avatar} fullName={props.fullName} length={30} />
								</ListItemAvatar>
							</ListItemButton>

							<Popper open={!!profileAnchor} anchorEl={profileAnchor} placement="top-end" style={{ zIndex: 99999 }} disablePortal={true}
								transition popperOptions={{ positionFixed: true }}>
								{({ TransitionProps }) =>
									<Grow {...TransitionProps} timeout={350} mountOnEnter unmountOnExit>
										<Paper className="profile-panel" elevation={1}>
											{/* User Box */}
											<div className="user-box">
												{/* Avatar */}
												<Avatar src={props.avatar} fullName={props.fullName} length={80} style={{ borderRadius: 5 }} />
												{/* Details */}
												<div className="user-details">
													<Typography variant="h6">{props.fullName}</Typography>
													<span>{props.email}</span>
												</div>
												{/* Button */}
												<ButtonLink to="/profile" size="small" color="primary" onClick={togglePanel}>
													View profile
												</ButtonLink>
											</div>
											{/* Navigation Links + Logout */}
											<List component="nav">
												{props.canEditProblem &&
													<ListItem button component={Link} to="/profile/submissions" onClick={togglePanel}>
														<ListItemIcon><FolderSharedIcon /></ListItemIcon>
														<ListItemText primary="My submissions" />
													</ListItem>
												}
												{props.canSubscribe &&
													<ListItem button component={Link} to="/subscription" onClick={togglePanel}>
														<ListItemIcon><SubscriptionsIcon /></ListItemIcon>
														<ListItemText primary="Subscription" />
													</ListItem>
												}
												{props.canImpersonate &&
													<ListItem button component={Link} to="/impersonate" onClick={togglePanel}>
														<ListItemIcon><ImpersonateIcon /></ListItemIcon>
														<ListItemText primary="Impersonate" />
													</ListItem>
												}
												<Divider />
												<ListItem button onClick={props.logOut}>
													<ListItemIcon><PowerSettingsNewIcon /></ListItemIcon>
													<ListItemText primary="Logout" />
												</ListItem>
											</List>
										</Paper>
									</Grow>
								}
							</Popper>
						</div>
					</div>
				</ClickAwayListener>

			</div>
		</div>
	);
	
});

SideMenu.propTypes = {
	userMenu: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	canView: PropTypes.object.isRequired,
	searchAll: PropTypes.func.isRequired,
	logOut: PropTypes.func.isRequired,
	active: PropTypes.string.isRequired,
	avatar: PropTypes.string.isRequired,
	fullName: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	isSideMenuCollapsed: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	userMenu: state.menu.userMenu,
	isOpen: state.layout.isSideMenuOpen,
	canView: canViewProps(state),
	notifications: state.userNotifications.userNotifications.filter(n => !n.readDate),
	notificationsLoading: state.userNotifications.isLoading,
	avatar: state.context.avatar || "",
	fullName: state.context.fullName,
	email: state.context.email,
	isSideMenuCollapsed: state.layout.isSideMenuCollapsed
});

const mapDispatchToProps = dispatch => ({
	collapseSideMenu: collapse => dispatch(collapseSideMenu(collapse)),
	getUserMenu: () => dispatch(getUserMenu()),
	logOut: () => dispatch(logOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(SideMenu, styles)));