import React from 'react';
import ComingSoonImage from './images/Coming Soon.png';

const ComingSoon = (props) => {
	return (
		<img src={ComingSoonImage} alt="Coming Soon" />
	);
};


export default ComingSoon;