import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../PageLayout';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { canSubmitCentres, canMakePublic, hasFunction } from '../../store/security';
import { getCentres, searchCentres, isLoading } from '../../store/centres';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import Button from '../widgets/Button';
// import ButtonLink from '../widgets/ButtonLink';
import CentreCard from './CentreCard';
import map from 'lodash/map';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import PagePagination from '../widgets/PagePagination';
import MakePublicButton from '../widgets/MakePublicButton';
import RegisterAsAuthorImage from '../images/research promo.png';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
	centresPageTitle : {
		width: '100%',
		'& h1': {
			width: '100%'
		}
	},
	centresTitle: {
		position: 'relative',
		'& a': {
			position: 'absolute',
			right: 0
		}
	}
});

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchCentres(values), 500);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "channel",
			label: "Channel",
			type: "autoComplete",
			defaultValue: "all",
			loadItems: { route: "channels", mapItem: "name" },
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "tag",
			label: "Filter by tag",
			type: "autoComplete",
			loadItems: { 
				route: (props, values) => `tags/centres`, 
				mapItem: "name" 
			},
			defaultItem: { label: "(All)", value: "all" }
		},
	]
}

const CentresController = props => {
	const { centres, classes } = props;

	React.useEffect(() => props.searchCentres(props.searchArgs, 1, 8), []);
	
	return (
		<PageLayout 
			classes={{
				pageTitle: classes.centresPageTitle
			}}
			title={
				<div className={classes.centresTitle}>
					<span>Research Finder</span>
					<span style={{ fontSize: '15px', marginLeft: '15px' }}>(only industry can list business challenges)</span>
					{!props.canSubmit && <Link to="/contact-us?subject=Register as Research Finder Author"><img src={RegisterAsAuthorImage} height="70" alt="Register as Author" /></Link>}
				</div>
			}
			pageContent={
				<React.Fragment>

					<Grid container spacing={3} alignItems="flex-end" style={{ paddingTop: 20, marginBottom: 10 }}>
						<Grid item xs={12} sm={4}>{props.fields.search()}</Grid>
						<Grid item xs={12} sm={4}>{props.fields.tag()}</Grid>
						<Grid item xs={12} sm={4}>{props.fields.channel()}</Grid>
						{/* {props.canSubmit &&
							<Grid item xs="auto">
								<ButtonLink to="/research-centres/create">
									Create research centre
								</ButtonLink>
							</Grid>
						} */}
						{!props.canPublishCentres && props.researchCentreRequestPublish && 
							<Grid item xs="auto">
								<Button 
									href="https://www.research2business.com/publish-request"
									color="secondary" 
									target="_blank"
								>
									Publish Request
								</Button>
							</Grid>
						}
					</Grid>
					{centres.length === 0 ? <p>No research centres found...</p> :
						<Grid container spacing={3}>
							{map(centres, (c, i) => <CentreCard key={i} centre={c} />)}
						</Grid>
					}
					<PagePagination
						pageNum={props.pageNum}
						pageSize={props.pageSize}
						total={props.total}
						totalPages={props.totalPages}
						onChangePage={page => {
							props.updateValues({
								...props.values,
								_pageNum: page,
								_pageSize: props.pageSize
							});
							props.searchCentres(props.values, page, props.pageSize);
						}}
						onChangeRowsPerPage={rowsPerPage => {
							props.updateValues({
								...props.values,
								_pageNum: 1,
								_pageSize: rowsPerPage
							});
							props.searchCentres(props.values, 1, rowsPerPage);
						}}
					/>
				</React.Fragment>
			} 
			breadcrumbs={["Research Finder"]} 
			pageActions={
				props.canMakePublic ?
					<MakePublicButton />
					: undefined
			}
		/>
	);
};

CentresController.propTypes = { 
	centres: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchCentres: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	totalPages: PropTypes.number,
	total: PropTypes.number,
	canMakePublic: PropTypes.bool.isRequired,
	canPublishCentres: PropTypes.bool.isRequired
};

CentresController.defaultProps = {
	pageNum: PropTypes.null,
	pageSize: PropTypes.null,
	totalPages: PropTypes.null,
	total: PropTypes.null
};

const mapStateToProps = (state, ownProps) => ({
	centres: state.centres.centres,
	pageNum: state.centres.pageNum,
	pageSize: state.centres.pageSize,
	total: state.centres.total,
	totalPages: state.centres.totalPages,
	loading: isLoading(state),
	searchArgs: { 
		status: "all", 
		tag: "all",
		...pick(getQueryParameters(ownProps.location.search), "search", "channel", "tag") 
	},
	canSubmit: canSubmitCentres(state),
	canMakePublic: canMakePublic(state),
	researchCentreRequestPublish: hasFunction("ResearchCentreRequestPublish")(state),
	canPublishCentres: hasFunction("CanPublishResearchCentres")(state)
});

const mapDispatchToProps = dispatch => ({
	searchCentres: (search, pageNum, pageSize) => dispatch(isEmpty(search) ? getCentres(pageNum, pageSize) : searchCentres(search, pageNum, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(withStyles(CentresController, styles)));