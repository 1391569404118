import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchMicroLearns, isLoading, capabilityThemes } from '../../store/microlearn';
import { canSubmitMicroLearn } from '../../store/security';
import { getQueryParameters } from '../../utils/utils';
import { withForm } from '../../utils/forms';
// import ButtonLink from '../widgets/ButtonLink';
import PageLayout from '../PageLayout';
import MicroLearnCard from './MicroLearnCard';
import pick from 'lodash/pick';
import map from 'lodash/map';
import PagePagination from '../widgets/PagePagination';
import ComingSoon from '../ComingSoon';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchMicroLearns(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} style={{ marginRight: 4 }} /> : <SearchIcon />
		},
		{
			name: "type",
			label: "Type",
			type: "select",
			items: [
				{ label: "(All)", value: "all" }, 
				{ label: "Academic Authored", value: "Academic" }, 
				{ label: "Industry Authored", value: "Industry" }
			]
		},
		{
			name: "capabilityTheme",
			label: "Capability Theme",
			type: "select",
			items: [
				{ label: "(All)", value: "all" }, 
				...capabilityThemes
			]
		}, 
		{
			name: "tag",
			label: "Filter by tag",
			type: "autoComplete",
			loadItems: { 
				route: (props, values) => `tags/microlearn?visibility=${values.visibility}`, 
				mapItem: "name" 
			},
			defaultItem: { label: "(All)", value: "all" }
		}
	]
};

const MicroLearnsController = props => {
	const { microLearns, fields } = props;

	if (!props.canSubmit) {
		return (
			<PageLayout 
				title="SkillsLab" 
				pageContent={
					<ComingSoon />
				} 
				breadcrumbs={["SkillsLab"]} 
			/>
		)
	}

	React.useEffect(() => props.searchMicroLearns(props.searchArgs, 1, 8), []);

	return (
		<PageLayout 
			title="SkillsLab" 
			pageContent={
				<React.Fragment>
					<Grid container spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
						<Grid item xs={12} sm={3}>{fields.search()}</Grid>
						<Grid item xs={12} sm={3}>{fields.type()}</Grid>
						<Grid item xs={12} sm={3}>{fields.capabilityTheme()}</Grid>
						<Grid item xs={12} sm={3}>{fields.tag()}</Grid>
						{/* {props.canSubmit && 
							<Grid item xs="auto">
								<ButtonLink to="/resources/create">Create new Skills Resource</ButtonLink>
							</Grid>
						} */}
					</Grid>
					{microLearns.length === 0 ? <p style={{ marginTop: 10 }}>Nothing found...</p> :
						<Grid container spacing={3}>
							{map(microLearns, (i, index) => <MicroLearnCard key={index} microLearn={i} />)}
						</Grid>
					}
					<PagePagination
						showCount={false}
						pageNum={props.pageNum}
						pageSize={props.pageSize}
						total={props.total}
						totalPages={props.totalPages}
						onChangePage={page => {
							props.updateValues({
								...props.values,
								_pageNum: page,
								_pageSize: props.pageSize
							});
							props.searchMicroLearns(props.values, page, props.pageSize);
						}}
						onChangeRowsPerPage={rowsPerPage => {
							props.updateValues({
								...props.values,
								_pageNum: 1,
								_pageSize: rowsPerPage
							});
							props.searchMicroLearns(props.values, 1, rowsPerPage);
						}}
					/>
				</React.Fragment>
			} 
			breadcrumbs={["SkillsLab"]} 
		/>
	);
};

MicroLearnsController.propTypes = { 
	microLearns: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchMicroLearns: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	organisationType: PropTypes.string,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	totalPages: PropTypes.number,
	total: PropTypes.number,
};

MicroLearnsController.defaultProps = {
	isCommunity: false,
	organisationType: '',
	pageNum: PropTypes.null,
	pageSize: PropTypes.null,
	totalPages: PropTypes.null,
	total: PropTypes.null
};

const mapStateToProps = (state, ownProps) => ({
	microLearns: state.microlearn.microLearns,
	pageNum: state.microlearn.pageNum,
	pageSize: state.microlearn.pageSize,
	total: state.microlearn.total,
	totalPages: state.microlearn.totalPages,
	loading: isLoading(state),
	canSubmit: canSubmitMicroLearn(state),
	searchArgs: pick(getQueryParameters(ownProps.location.search), "search")
});

const mapDispatchToProps = dispatch => ({
	searchMicroLearns: (args, pageNum, pageSize) => dispatch(searchMicroLearns(args, pageNum, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(MicroLearnsController));
