import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getQueryParameters, stripHtml, truncateString } from '../../utils/utils';
import DataTableColumn from '../widgets/dataTable/DataTableColumn';
import DataTable from '../widgets/dataTable/DataTable';
import SearchField from '../widgets/SearchField';
import Card from '../widgets/Card';
import PageLayout from '../PageLayout';
import { canViewUsers } from '../../store/security';
import { get } from '../../utils/ajax';
import axios from 'axios';

const IPEnquiriesController = props => {
	const [loading, setLoading] = React.useState(false);
	const [ip, setIP] = React.useState({});
	const [enquiries, setEnquiries] = React.useState([]);
	const [search, setSearch] = React.useState("");
	const [ipTitles, setIPTitles] = React.useState([]);
	const hasIP = Boolean(ip.ipId);
	const { ipId } = getQueryParameters(props.location.search);

	React.useEffect(() => {
		setLoading(true);
		axios.all([
			get({
				url: `/api/ip/${ipId}`,
				onSuccess: data => {
					setIP(data);
				}
			}),
			get({
				url: `/api/ip/${ipId}/enquiries`,
				onSuccess: data => {
					setEnquiries(data);
				}
			})
		]).then(responseArr => {
			setLoading(false);
		});

	}, [ipId]);

	// Store unique ip titles to save calculating on each cycle
	React.useEffect(() => {
		if (enquiries.length > 0) {
			setIPTitles(enquiries.reduce((acc, e) => acc.includes(e.ipTitle) ? acc : [...acc, e.ipTitle], []));
		}
	}, [enquiries.length]);

	return (
		<PageLayout title="IP Enquiries" pageContent={
			<Card title={`Search Enquiries${hasIP ? `: ${ip.title}` : ""}`}>
				<SearchField value={search} onChange={value => setSearch(value)} loading={loading} />
				{/* Datatable */}
				<DataTable data={enquiries} search={search} canViewUsers={props.canViewUsers} enableExport>
					<DataTableColumn name="fullName" label="User" dataType="avatar" />
					{!hasIP &&
						<DataTableColumn 
							name="ipTitle" 
							label="IP"
							canFilter 
							filterType="AutoComplete"
							items={ipTitles}
							renderer={c => c.ipExists ? <Link to={`/ips/${c.ipId}`}>{c.ipTitle}</Link> : c.ipTitle} 
						/>
					}
					<DataTableColumn name="content" label="Content" renderer={e => truncateString(stripHtml(e.content), hasIP ? 100 : 50)} />
					<DataTableColumn name="submissionDate" label="Submission Date" dataType="date" />
					<DataTableColumn name="status" label="Status" />
				</DataTable>
			</Card>
		} breadcrumbs={[{ label: "IP Hub", path: "/ip" }, "IP Enquiries"]} />
	);
};

IPEnquiriesController.propTypes = { 
	canViewUsers: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	canViewUsers: canViewUsers(state)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(IPEnquiriesController);