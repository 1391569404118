import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewCentre from './ViewCentre';
import { getCentre, isLoading, publishCentre, unpublishCentre } from '../../store/centres';
import { canEditCentre, canMakePublic, hasFunction } from '../../store/security';
import MakePublicButton from '../widgets/MakePublicButton';

const ViewCentreController = props => {
	React.useEffect(() => props.getCentre(props.match.params.centreId), [props.match.params.centreId]);

	return (
		<PageLayout 
			title="View research centre" 
			pageContent={
				<ViewCentre 
					centre={props.centre} 
					loading={props.loading} 
					canEdit={props.canEdit} 
					canPublishCentres={props.canPublishCentres}
					publishCentre={props.publishCentre}
					unpublishCentre={props.unpublishCentre}
				/>
			} 
			breadcrumbs={[{ label: "Research Finder", path: "/research-centres" }, "View research centre"]} 
			pageActions={
				props.canMakePublic ?
					<MakePublicButton />
					: undefined
			}
		/>
	);;
};

ViewCentreController.propTypes = {
	centre: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canMakePublic: PropTypes.bool.isRequired,
	canPublishCentres: PropTypes.bool.isRequired,
	publishCentre: PropTypes.func.isRequired,
	unpublishCentre: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	centre: state.centres.centre,
	loading: isLoading(state),
	canEdit: canEditCentre(state),
	canMakePublic: canMakePublic(state),
	canPublishCentres: hasFunction("CanPublishResearchCentres")(state)
});

const mapDispatchToProps = dispatch => ({
	getCentre: centreId => dispatch(getCentre(centreId)),
	publishCentre: centreId => dispatch(publishCentre(centreId)),
	unpublishCentre: centreId => dispatch(unpublishCentre(centreId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCentreController);