import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    CircularProgress,
    Dialog,
    Divider,
    Fab as FloatingActionButton,
    Fade,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popper,
    Slide
} from '@mui/material';
import {
    Message as ChatIcon,
    Send as SendIcon,
    ArrowBack as BackIcon,
    ArrowDownward as HideIcon,
    Add as AddIcon,
    Close as CloseIcon
}from '@mui/icons-material';
import Button from './Button';
import Card from './Card';
import TextField from './TextField';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import find from 'lodash/find';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { default as formatDate } from 'date-fns/format';
import isToday from 'date-fns/isToday';
import { withStyles } from 'tss-react/mui';
import { get, post } from '../../utils/ajax';
import SearchField from './SearchField';
import { hubConnection } from '../../messageHub';
import {
    toggleChat
} from '../../store/messages';
import Avatar from '../widgets/Avatar';

const styles = theme => ({
    root: {
      width: '100%',
      maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
});

class ChatButton extends React.Component {
    state = {
        page: 'sessions',
        sessions: [],
        session: { messages: [] },
        message: "",
        loading: false.lastMessage,
        sendLoading: false,
        contacts: [],
        contactsSearchText: ""
    }

    componentDidMount = () => {
        const _this = this; // hubConnection event overrides this
		hubConnection.on("new-message", data => {
            const props = _this.props;
            const state = _this.state;
            if (props.userId !== data.message.sentByUserId) {
                if (state.session && state.session.messages && state.session.messageGroupId === data.group.messageGroupId) {
                    // console.log('go time');
                    _this.setState({
                        ...state,
                        session: {
                            ...state.session,
                            messages: [
                                ...state.session.messages,
                                data.message
                            ]
                        }
                    }, () => {
                        if (_this.sessionScrollbar) {
                            _this.sessionScrollbar.scrollToBottom();
                        }
                    });
                }
            }
		});
    }

	componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.showChat && this.props.showChat) {
            this.loadSessions();
        }
        if (this.props.toUserId && prevProps.toUserId !== this.props.toUserId) {
            if (this.props.toUserId === this.props.userId) {
                // Can't chat to self so just load sessions page
                this.setState({
                    ...this.state,
                    page: "sessions"
                }, () => {
                });
            } else {
                this.loadContact({
                    userId: this.props.toUserId
                });
                this.setState({
                    ...this.state,
                    page: "session"
                }, () => {
                });
            }
        }
    }

    onClose = () => { this.props.toggleChat(false); }

	sendMessage = () => {
        const message = this.state.message;
        const session = this.state.session;
        const sessions = this.state.sessions;

		if (message) {
            this.setState({
                ...this.state,
                sendLoading: true
            });
            post({
                url: '/api/messages/send',
                data: {
                    message: message,
                    messageGroupId: session.messageGroupId,
                    userId: session.contactId
                },
                onSuccess: data => {
                    this.setState({
                        ...this.state,
                        sendLoading: false,
                        message: ""
                    });
                    if (!find(sessions, s => s.messageGroupId === data.message.messageGroupId)) {
                        this.setState({
                            ...this.state,
                            sessions: [
                                ...this.state.sessions,
                                {
                                    ...this.state.session,
                                    messageGroupId: data.message.messageGroupId,
                                    lastMessage: data.message.content,
                                    messages: [{
                                        sentDate: data.message.sentDate,
                                        sentByUserId: data.message.sentByUserId,
                                        sentBy: data.message.sentBy,
                                        content: data.message.content
                                    }]
                                }
                        ]});
                    }
                    this.setState({
                        ...this.state,
                        session: {
                            ...session,
                            messages: [
                                ...session.messages,
                                {
                                    sentDate: data.message.sentDate,
                                    sentByUserId: data.message.sentByUserId,
                                    sentBy: data.message.sentBy,
                                    content: data.message.content
                                }
                            ]
                        }
                    }, () => {
                        if (this.sessionScrollbar) {
                            this.sessionScrollbar.scrollToBottom();
                        }
                    });
                },
                onError: () => {
                    this.setState({
                        ...this.state,
                        sendLoading: false
                    });
                }
            });
		}
	};
	
	onKeyDown = e => {
		if (e.keyCode === 13 && !e.shiftKey) {
			e.preventDefault();
			this.sendMessage();
		}
	}

    loadSessions = () => {
        this.setState({
            ...this.state,
            loading: true
        });
        get({
			url: '/api/messages/groups',
			onSuccess: data => {
                this.setState({
                    ...this.state,
                    loading: false,
                    sessions: map(data, s => {
                        const to = find(s.users, u => u.userId !== this.props.userId);
                        if (to) {
                            s.to = to.fullName;
                            s.toAvatar = to.avatar;
                        }
                        return s;
                    })
                });
            },
			onError: () => {
                this.setState({
                    ...this.state,
                    loading: false,
                    sessions: []
                });
            }
		});
    }

    loadContacts = () => {
        this.setState({
            ...this.state,
            loading: true
        });
        get({
			url: '/api/users/by-organisation',
			onSuccess: data => {
                this.setState({
                    ...this.state,
                    loading: false,
                    contacts: data
                });
            },
			onError: () => {
                this.setState({
                    ...this.state,
                    loading: false,
                    contacts: []
                });
            }
		});
    }

    loadSession = (session) => {
        this.setState({
            ...this.state,
            loading: true,
            session: {
                contactId: session.userId,
                contact: session.fullName,
                to: session.fullName,
                since: '',
                lastMessage: session.lastMessage,
                messages: session.messages
            }
        });
        get({
			url: `/api/messages/group/${session.messageGroupId}`,
			onSuccess: data => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                if (data) {
                    const contact = find(data.users, u => u.userId !== this.props.userId) || {};
                    this.setState({
                        ...this.state,
                        session: {
                            to: contact.fullName,
                            toAvatar: contact.avatar,
                            messageGroupId: contact.messageGroupId,
                            contactId: contact.userId,
                            contact: contact.fullName,
                            since: '',
                            lastMessage: data.lastMessage,
                            messages: data.messages
                        }
                    });
                    if (this.sessionScrollbar) {
                        this.sessionScrollbar.scrollToBottom();
                    }
                }
            },
			onError: () => {
                this.setState({
                    ...this.state,
                    loading: false
                });
            }
		});
    }

    loadContact = (contact) => {
        this.setState({
            ...this.state,
            loading: true,
            session: {
                contactId: contact.userId,
                contact: contact.fullName,
                to: contact.fullName,
                toAvatar: contact.avatar,
                since: '',
                lastMessage: '',
                messages: []
            }
        }, () => {
            get({
                url: `/api/messages/group-by-to-user/${contact.userId}`,
                onSuccess: data => {
                    this.setState({
                        ...this.state,
                        loading: false
                    }, () => {
                        if (data) {
                            const to = find(data.users, u => u.userId !== this.props.userId);
                            this.setState({
                                ...this.state,
                                session: {
                                    contactId: contact.userId,
                                    contact: to ? to.fullName : contact.fullName,
                                    messageGroupId: data.messageGroupId,
                                    to: to ? to.fullName : contact.fullName,
                                    toAvatar: to ? to.avatar : contact.avatar,
                                    since: '',
                                    lastMessage: data.lastMessage,
                                    messages: data.messages
                                }
                            }, () => {
                                if (this.sessionScrollbar) {
                                    this.sessionScrollbar.scrollToBottom();
                                }
                            });
                        }
                    });
                },
                onError: () => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                }
            });
        });
    }

    renderMessage = (message, index) => {
		const date = new Date(message.sentDate),
			isAuthor =  message.sentByUserId === this.props.userId, //props.currentUserId,
			wasPrevious = true; //message.userId === previousAuthor;

		return (
			<Slide key={index} direction={isAuthor ? "right" : "left"} in timeout={500} mountOnEnter>
				<li className={classNames({ "hide-author": isAuthor || wasPrevious, "new-author": !wasPrevious })}>
					<Avatar src={message.sentByAvatar} fullName={message.sentBy} length={45} />
					<div className={classNames("content", { "odd": isAuthor })}>
						<span className="h5">{message.sentBy}</span>
						<div className="box">{message.content}</div>
					</div>
					<span className="time">
						{date && formatDate(date, `h:mm a${isToday(date) ? "" : " d/M/yyyy"}`)}
					</span>
				</li>
			</Slide>
		);
	}

    render = () => {
        const classes = this.props.classes;
    
        // React.useEffect(() => {
        //     // const s2 = session;
    
        // }, []);
    
        const style = {
            right: '10px',
            bottom: '30px',
            position: 'fixed',
            zIndex: '9999'
        };

        
        const actions = [
        <Button
            label="Cancel"
            primary={true}
            onClick={this.onClose}
        />,
        <Button
            label="Submit"
            primary={true}
            keyboardFocused={true}
            onClick={() => this.onSubmit() }
        />,
        ];

        const ContactsPanel = 
            <div className="message-section">
                <h4 style={{ marginBottom: "0.75rem" }}>
                    <FloatingActionButton 
                        color="primary" 
                        aria-label="Back" 
                        size="small"
                        style={{
                            marginTop: '-3px',
                            marginRight: '10px'
                        }}
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                page: 'sessions'
                            });
                        }}
                    >
                        <BackIcon />
                    </FloatingActionButton>
                    Contacts
                    {this.state.loading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
                </h4>
                <SearchField
                    placeholder="Search contacts..."
                    value={this.state.contactsSearchText}
                    onChange={value => this.setState({ ...this.state, contactsSearchText: value })}
                />
                <Scrollbars
                    style={{ height: 400 }}
                    renderTrackHorizontal={() => <div style={{ display: "none" }} />} 
                    ref={this.ref}
                >
                    <List className={classes.root}>
                        {map(filter(this.state.contacts, c => this.state.contactsSearchText === "" || includes(toLower(c.fullName), toLower(this.state.contactsSearchText))), (contact, index) => 
                            <ListItem 
                                key={contact.userId}
                                alignItems="flex-start" 
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        page: 'session'
                                    }, () => {
                                        this.loadContact(contact);
                                    });
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <ListItemAvatar>
                                    <Avatar src={contact.avatar} fullName={contact.fullName} length={45} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={contact.fullName}
                                    secondary={contact.positionTitle}
                                />
                            </ListItem>
                        )}
                    </List>
                </Scrollbars>
            </div>;

        const SessionsPanel = 
                <div className="message-section">
                    <h4 style={{ marginBottom: "0.75rem" }}>
                        <FloatingActionButton 
                            color="primary" 
                            aria-label="New Chat Session" 
                            size="small"
                            style={{
                                marginTop: '-3px',
                                marginRight: '10px'
                            }}
                            onClick={() => {
                                this.loadContacts();
                                this.setState({
                                    ...this.state,
                                    page: 'contacts'
                                });
                            }}
                        >
                            <AddIcon />
                        </FloatingActionButton>
                        Chat Sessions
                        {this.state.loading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
                    </h4>
                    <Scrollbars 
                        renderTrackHorizontal={() => <div style={{ display: "none" }} />} 
                        ref={this.ref}
                        style={{ height: 400 }}
                    >
                        <List className={classes.root}>
                            {map(this.state.sessions, (session, index) => 
                                <ListItem 
                                    key={session.messageGroupId}
                                    alignItems="flex-start" 
                                    onClick={() => { 
                                        this.loadSession(session);
                                        this.setState({
                                            ...this.state,
                                            page: 'session'
                                        });
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <ListItemAvatar>
                                        <Avatar alt={session.to} src={session.toAvatar} fullName={session.to} length={45} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={session.to}
                                        secondary={session.lastMessage}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Scrollbars>
                </div>
        ;

        const session = this.state.session;
        const SessionPanel = 
            <React.Fragment>
                <div className="message-section">
                    <h4 style={{ marginBottom: "0.75rem" }}>
                        <FloatingActionButton 
                            color="primary" 
                            aria-label="Back" 
                            size="small"
                            style={{
                                marginTop: '-3px',
                                marginRight: '10px'
                            }}
                            onClick={() => {
                                this.setState({
                                    ...this.state,
                                    page: 'sessions'
                                });
                            }}
                        >
                            <BackIcon />
                        </FloatingActionButton>
                        {session.to}
                        {this.state.loading && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
                    </h4>
                    <Scrollbars 
                        renderTrackHorizontal={() => <div style={{ display: "none" }} />} 
                        ref={ref => {
                            this.sessionScrollbar = ref;
                        }}
                        style={{ height: 400 }}
                    >
                        {!session || session.messages.length === 0 ? <span>No messages</span> :
                            <ul className="message-list">
                                {map(session.messages, (message, index) => this.renderMessage(message, index))}
                            </ul>
                        }
                    </Scrollbars>
                </div>
                <Divider />
                <div className="message-editor">
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item xs>
                            <TextField
                                value={this.state.message}
                                placeholder="Type your message here..."
                                onChange={e => this.setState({ ...this.state, message: e.target.value })}
                                onKeyDown={this.onKeyDown}
                                fullWidth
                                multiline
                                disabled={this.state.loading}
                                helpText={<span>Start new paragraph with <kbd>SHIFT</kbd> + <kbd>ENTER</kbd></span>}
                            />
                        </Grid>
                        <Grid item xs="auto" style={{ position: "relative" }}>
                            <FloatingActionButton 
                                color="primary" 
                                aria-label="send"
                                disabled={this.state.sendLoading} 
                                onClick={this.sendMessage}
                            >
                                <SendIcon />
                            </FloatingActionButton>
                            {this.state.loading && <CircularProgress size={68} color="secondary" style={{ position: "absolute", top: 6, left: 6, zIndex: 1 }} />}
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>;

        let PageElement = null;
        switch (this.state.page) {
            case 'sessions':
                PageElement = SessionsPanel;
                break;
            case 'contacts':
                PageElement = ContactsPanel;
                break;
            case 'session':
                PageElement = SessionPanel;
                break;
            default:
        }

        return (
            <div className="chat-button">
                <Popper
                    className='popper-root'
                    open={this.props.showChat}
                    anchorEl={this.buttonRef}
                    placement='bottom-end'
                    transition
                    style={{ zIndex: 9999 }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div>
                            <Card className="chat" width="500px" style={{ position: 'relative' }}>
                                <FloatingActionButton 
                                    size="small"
                                    style={{
                                        position: 'absolute',
                                        top: '-16px',
                                        right: '-16px'
                                    }}
                                    onClick={() => {
                                        this.props.toggleChat(false);
                                    }}
                                >
                                    <CloseIcon />
                                </FloatingActionButton>
                                {PageElement}
                            </Card>
                            </div>
                        </Fade>
                    )}  
                </Popper>

                <Dialog
                    title="Log an Issue"
                    actions={actions}
                    open={false}
                    onClose={this.onClose}>
                        <p>Enter a brief description of the issue then click submit</p>
                    
                </Dialog>
                <FloatingActionButton 
                    size="small" 
                    style={style} 
                    onClick={() => this.props.toggleChat(!this.props.showChat)}
                    ref={ref => {
                        this.buttonRef = ref;
                    }}
                >
                    {this.props.showChat ? <HideIcon /> : <ChatIcon />}
                </FloatingActionButton>
            </div>
        );
    };
}

ChatButton.propTypes = {
    userId: PropTypes.string.isRequired,
    toUserId: PropTypes.string,
    showChat: PropTypes.bool.isRequired,
    toggleChat: PropTypes.func.isRequired
};

ChatButton.defaultProps = {
    toUserId: null
};

const mapStateToProps = (state) => ({
    userId: state.context.userId,
    toUserId: state.messages.toUserId,
    showChat: state.messages.showChat
});

const mapDispatchToProps = (dispatch) => ({
    toggleChat: show => dispatch(toggleChat(show))
});

export default withStyles(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ChatButton),
    styles);
