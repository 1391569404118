import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { withForm } from '../../utils/forms';
import { getQueryParameters } from '../../utils/utils';
import { searchCourses, isLoading, deleteCourse, copyCourse } from '../../store/courses';
import { showModal } from '../../store/modal';
import { canEditCourse, canMakePublic } from '../../store/security';
import PageLayout from '../PageLayout';
import ButtonLink from '../widgets/ButtonLink';
import Card from '../widgets/Card';
import PagePagination from '../widgets/PagePagination';
import CompetenciesViewer from '../widgets/CompetenciesViewer';
import CourseCard from './CourseCard';
import CopyCourseDialog from './CopyCourseDialog';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import mapValues from 'lodash/mapValues';
import MakePublicButton from '../widgets/MakePublicButton';

let timer = null;
const form = {
	initValues: props => ({ ...props.searchArgs, _pageNum: props.pageNum, _pageSize: props.pageSize }),
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchCourses(values, 1, values._pageSize), ["search", "provider"].includes(name) ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "category",
			label: "Category",
			type: "autoComplete",
			defaultValue: "all",
			loadItems: { route: "course-categories", mapItem: "name" },
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "channel",
			label: "Channel",
			type: "autoComplete",
			defaultValue: "all",
			loadItems: { route: "channels", mapItem: "name" },
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "provider",
			placeholder: "Provider",
			startAdornment: props => props.loading ? <CircularProgress size={20} /> : <SearchIcon />
		},
		{
			name: "mode",
			label: "Mode",
			type: "select",
			defaultValue: "all",
			items: [{ label: "(All)", value: "all" }, "Online", "Digital Classroom", "Face-to-face", "Blended"]
		},
		{
			name: "order",
			label: "Order By",
			type: "select",
			defaultValue: "Relevance",
			items: ["Relevance", "Title", "Provider", "Favourite"]
		}
	]
};

const CoursesController = props => {
	const [viewer, setViewer] = React.useState({ open: false, competencies: [] });
	const [copier, setCopier] = React.useState({ open: false, course: {} });
	
	React.useEffect(() => {
		var defaultPageSize = 25;
		if (window.innerWidth < 960) {
			defaultPageSize = 4;
		} else if (window.innerWidth < 1280) {
			defaultPageSize = 6;
		} else {
			defaultPageSize = 9;
		}
		props.searchCourses(props.searchArgs, 1, defaultPageSize);
	}, []);

	const orderedByType = orderBy(props.courses, [(i) => i.courseCategoryOrder]);
	const sorted = reduce(orderedByType, (acc, c) => {
		(acc[c.courseCategoryName] || (acc[c.courseCategoryName] = [])).push(c);
		return acc;
	}, {});

	return (
		<PageLayout 
			title="Courses" 
			pageContent={
				<React.Fragment>
					<div style={{ textAlign: "right" }}>
						<ButtonLink disabled to="/courses/request">Request custom course</ButtonLink>
					</div>
					<Card style={{ margin: "20px 0", zIndex: 10 }} expandable={true}
						collapsedContent={
							<Grid container spacing={3} alignItems="flex-end" style={{ }}>
								<Grid item xs={12}>{props.fields.search()}</Grid>
							</Grid>	
						}				
					>
						<Grid container spacing={3} alignItems="flex-end">
							<Grid item xs={12}>{props.fields.search()}</Grid>
							<Grid item xs={12} sm={6}>{props.fields.category()}</Grid>
							<Grid item xs={12} sm={6}>{props.fields.channel()}</Grid>
							<Grid item xs={12} sm>{props.fields.provider()}</Grid>
							<Grid item xs={12} sm={3}>{props.fields.mode()}</Grid>
							<Grid item xs={12} sm={3}>{props.fields.order()}</Grid>
						</Grid>
					</Card>
					{props.courses.length === 0 && <p>No courses found...</p>}
					{map(Object.keys(sorted), type =>
						<React.Fragment key={type}>
							<h2 style={{ marginTop: 20 }}>{type}</h2>
							<Grid container spacing={3}>
								{map(sorted[type], (c, i) => 
									<CourseCard 
										key={i} 
										course={c} 
										canEdit={props.canEdit(c)} 
										showCompetencies={() => setViewer({ open: true, competencies: c.competencies })}
										deleteCourse={props.deleteCourse}
										copyCourse={() => setCopier({ open: true, course: c })}
										organisationId={props.organisationId}
									/>
								)}
							</Grid>
						</React.Fragment>
					)}
					<PagePagination
						pageNum={props.pageNum}
						pageSize={props.pageSize}
						total={props.total}
						totalPages={props.totalPages}
						onChangePage={page => {
							props.updateValues({
								...props.values,
								_pageNum: page,
								_pageSize: props.pageSize
							});
							props.searchCourses(props.values, page, props.pageSize);
						}}
						onChangeRowsPerPage={rowsPerPage => {
							props.updateValues({
								...props.values,
								_pageNum: 1,
								_pageSize: rowsPerPage
							});
							props.searchCourses(props.values, 1, rowsPerPage);
						}}
					/>

					<CompetenciesViewer 
						competencies={viewer.competencies} 
						open={viewer.open} 
						onClose={() => setViewer({ open: false, competencies: [] })} 
					/>
					<CopyCourseDialog
						title={`${copier.course.title} - Copy`}
						open={copier.open} 
						onClose={() => setCopier({ open: false, course: {} })} 
						copyCourse={(title, include) => {
							props.copyCourse(copier.course.courseId, title, include);
							setCopier({ open: false, course: {} });
						}}
					/>
				</React.Fragment>
			} 
			breadcrumbs={["Courses"]} 
			pageActions={
				props.canMakePublic ?
					<MakePublicButton />
					: undefined
			}
		/>
	);
};

CoursesController.propTypes = { 
	courses: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	organisationId: PropTypes.number.isRequired,
	organisation: PropTypes.string.isRequired,
	canEdit: PropTypes.func.isRequired,
	searchCourses: PropTypes.func.isRequired,
	deleteCourse: PropTypes.func.isRequired,
	copyCourse: PropTypes.func.isRequired,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	totalPages: PropTypes.number,
	total: PropTypes.number
};

CoursesController.defaultProps = {
	pageNum: PropTypes.null,
	pageSize: PropTypes.null,
	totalPages: PropTypes.null,
	total: PropTypes.null,
	canMakePublic: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	courses: state.courses.courses,
	pageNum: state.courses.pageNum,
	pageSize: state.courses.pageSize,
	total: state.courses.total,
	totalPages: state.courses.totalPages,
	loading: isLoading(state),
	organisationId: state.context.organisationId,
	organisation: state.context.organisationTitle || "",
	searchArgs: mapValues(
		pick(getQueryParameters(ownProps.location.search), "search", "category", "channel", "provider", "mode", "businessProfileId", "order"),
		(value, key) => key === "businessProfileId" ? (value ? parseInt(value, 10) : value) : value
	),
	canEdit: canEditCourse(state),
	canMakePublic: canMakePublic(state)
});

const mapDispatchToProps = dispatch => ({
	searchCourses: (args, pageNum, pageSize) => dispatch(searchCourses(args, pageNum, pageSize)),
	deleteCourse: courseId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this course?",
		onOk: () => dispatch(deleteCourse(courseId))
	})),
	copyCourse: (courseId, title, includeCompetencies) => 
		dispatch(copyCourse(courseId, title, includeCompetencies))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(CoursesController));