import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditIP from './EditIP';
import { saveIP, isLoading } from '../../store/ipRegister';
import PageLayout from '../PageLayout';

const RegisterIPController = props =>
	<PageLayout title="Register IP" pageContent={
		<EditIP loading={props.loading} onSave={props.saveIP} isNew />
	} breadcrumbs={[{ label: "IP Hub", path: "/ip" }, "Register IP"]} />;

RegisterIPController.propTypes = {
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveIP: ip => dispatch(saveIP(ip))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterIPController);