import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import ViewIP from './ViewIP';
import { getIP, isLoading } from '../../store/ipRegister';
import { canEditIP, canViewIPEnquiries } from '../../store/security';
import { post } from '../../utils/ajax';
import CreateEnquiry from './CreateEnquiry';
import { showModal } from '../../store/modal';
import { showSuccessNotification, showErrorNotification } from '../../store/notifications';

const ViewIPController = props => {
	const [enquiryLoading, setEnquiryLoading] = React.useState(false);
	const { ipId } = props.match.params;

	React.useEffect(() => {
		props.getIP(ipId);
		post({ url: `/api/ip/${ipId}/log-view` });
	}, []);

	return (
		<PageLayout 
			title="View IP" 
			pageContent={
				<React.Fragment>
					<ViewIP 
						ip={props.ip} 
						loading={props.loading} 
						canEdit={props.canEdit} 
						canViewEnquiries={props.canViewEnquiries}
						currentUserId={props.currentUserId} 
						successColour={props.successColour} 
						errorColour={props.errorColour} 
					/>
					<CreateEnquiry 
						saveEnquiry={content => {
							props.dispatch(showModal("SIMPLE", {
								title: "Are sure you want to submit an enquiry?",
								message: "The owners of this IP will contact you with a response at a later date.",
								onOk: () => {
									setEnquiryLoading(true);
									post({
										url: `/api/ip/${ipId}/enquire`,
										data: { content },
										onSuccess: data => {
											setEnquiryLoading(false);
											if (data.success) {
												props.showSuccessNotification(data.message);
											} else {
												props.showErrorNotification(data.message);
												
											}
										},
										onError: () => setEnquiryLoading(false)
		})
								}
							}));
						}}
						loading={enquiryLoading} 
					/>
				</React.Fragment>
			} 
			breadcrumbs={[
				{ label: "IP Hub", path: "/ip" }, 
				"View IP"
			]} 
		/>
	);
};

ViewIPController.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	ip: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canViewEnquiries: PropTypes.bool.isRequired,
	successColour: PropTypes.string.isRequired,
	errorColour: PropTypes.string.isRequired,
	showErrorNotification: PropTypes.func.isRequired,
	showSuccessNotification: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	currentUserId: state.context.userId,
	ip: state.ipRegister.ip,
	loading: isLoading(state),
	canEdit: canEditIP(state),
	canViewEnquiries: canViewIPEnquiries(state),
	successColour: state.theme.successColour,
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
	dispatch: dispatch,
	showErrorNotification: (message) => dispatch(showErrorNotification(message)),
	showSuccessNotification: message => dispatch(showSuccessNotification(message)),
	getIP: ipId => dispatch(getIP(ipId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewIPController);
